<template>
  <div>
    <sidebar />
    <div class="main">
      <upbar title="" class="pb-5" /> <br />
      <b-alert v-model="showPullErrorAlert" variant="danger">{{
        pullErrorMessage
      }}</b-alert>

      <!-- ---------------------------------- Connectors State List ----------------------------------------->
      <div class="title">
        Connectors State
        <b-button class="btn bg-transparent btn-outline-light" @click="refresh">
          <b-icon icon="arrow-clockwise" variant="dark" scale="1"></b-icon>
        </b-button>

        <b-button class="btn bg-transparent btn-outline-light" @click="display_div = !display_div">
          <b-icon icon="caret-right" variant="dark" scale="1" v-if="display_div"></b-icon>
           <b-icon icon="caret-down" variant="dark" scale="1" v-if="!display_div"></b-icon>
        </b-button>
        
      </div>
      <br />
      <br />
      <div v-if="display_div" >
      <div class="bar row" >
        <div class="col-1"></div>
        <div class="col-4">
          <p class="tabTitle">Connector Name</p>
        </div>
        <div class="col-3">
          <p class="tabTitle">State</p>
        </div>
        <div class="col-4">
          <p class="tabTitle">Actions</p>
        </div>
      </div>
      <div v-if="endpointStates.length === 0" class="row">
        <p>
          <br />It looks like you don't have any source Backup connectors yet. Please
          <a @click="goToConnectorsPage" class="link">click here</a> to create
          your first connector.
        </p>
      </div>
      <div v-for="con in endpointStates" :key="con.endpointId" class="bar1 row">
        <div class="col-1">
          <img :src="getLogoConnector(con.endpointType)" class="icon" />
        </div>
        <div class="col-4">
          <p class="tabText">{{ con.endpointName }}</p>
        </div>
        <div class="col-3">
          <p class="tabText">Pull {{ con.statusId }}</p>
        </div>
        <div class="col-4">
          <div class="buttons">
            <b-button
              variant="outline-primary"
              class="botton-wide"
              @click="modalEndpointLogs(con.endpointId)"
              >Logs</b-button
            >
            <b-button
              variant="primary"
              class="botton-wide blue ml-3"
              v-on:click="repullConnector(con.endpointId)"
              >Pull Again</b-button
            >
          </div>
        </div>
      </div>
      </div>

      <!-- --------------------------------- Backup list ------------------------------ -->

      <div class="top">
        <b-alert v-model="showErrorAlert" variant="danger">{{
          backupErrorMessage
        }}</b-alert>
        <div class="mt-4">
          <div class="title">
            Planner Backup List
            <b-button
              class="btn bg-transparent btn-outline-light"
              @click="refresh"
            >
              <b-icon icon="arrow-clockwise" variant="dark" scale="1"></b-icon>
            </b-button>
          </div>

          <b-dropdown  class="plus-for-migrate mt-5"  text="Backup & Restore">
            <b-dropdown-item @click="startBackup">Start</b-dropdown-item>
            <b-dropdown-item @click="stopBackup"> Stop</b-dropdown-item>
            <b-dropdown-item @click="restore"> Restore </b-dropdown-item>
          </b-dropdown>
    
           <div class="plus-white-trash mt-5" @click="deleteBackup">
            <b-icon
              class="bicon-trash"
              variant="danger"
              icon="trash-fill"
              aria-hidden="true"
              @click="modalDeleteBackup(backup.id)"
            >
            </b-icon>
            </div>
          <b-input-group class="plus-white mt-5">
            <b-input-group-prepend is-text>
              <b-icon class="altosio-icon" icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              type="search"
              size="lg"
              v-model="search"
              placeholder="Search..."
            ></b-form-input>
          </b-input-group>

          <div class="plus-entries mt-5">
            <div class="plusIn">
              {{backups.length}} entries
            </div>
          </div>
        </div>
      </div>
      <br />
      <br /><br />

      <div class="bar row">
        <div class="col-1">
          <input type="checkbox" class="group1" v-model="selectAll" />
        </div>
        <div class="col-1">
          <p class="tabTitle">
            <label for="vehicle1"> Status </label>
          </p>
        </div>
        <div class="col-1"></div>
        <div class="col-2">
          <p class="tabTitle">Source Name</p>
        </div>
        <div class="col-1">
          
        </div>

        <div class="col-2">
          <p class="tabTitle">Target Name</p>
        </div>
         <div class="col-1">
          <p class="tabTitle">Backup</p>
        </div>
        <div class="col-1">
          <p class="tabTitle">Frequency</p>
        </div>
        <div class="col-1">
          <p class="tabTitle">Last Backup</p>
        </div>
        <div class="col-1">
          <p class="tabTitle">Actions</p>
        </div>
      
      </div>

      <div v-for="backup in filteredList" :key="backup.id" class="bar1 row">
        <div class="col-1"> 
          <input
            type="checkbox"
            class="group pr-3"
            :value="backup.id"
            v-model="selected"
          />
        </div>
        <div class="col-1"  @click="modalLogs(backup.id)">
          <p class="tabText">
            <label for="vehicle1"> {{ backup.statusId }}</label>
          </p>
        </div>
        <div class="col-1">
          <img :src="getTypeLogo(backup.sourceType)" class="icon" />
        </div>
        <div class="col-2" @click="modalLogs(backup.id)">
          <p class="tabText">
            {{ backup.sourceName }}
          </p>
        </div>
        <div class="col-1">
          <img
            v-if="backup.targetEndpointId != null && backup.targetEndpointId != 0"
            :src="getTypeLogo(backup.targetEndpointType)"
            class="target-icon"
          />
          <b-icon
            v-else
            class="bicon-questionmark"
            variant="danger"
            icon="question"
            aria-hidden="true"
          />
        </div>
        <div class="col-2">
           <p class="tabText">{{ backup.targetName}}</p>
        </div>
         
         <div class="col-1">
                 <b-form-checkbox 
                 class="isBackupActiveSwitch"
                  v-model="backup.isBackupActive" 
                  switch size="lg" 
                   @change="enableOrDisableBackup(backup.id, $event)"></b-form-checkbox>
        </div>
        <div class="col-1">
           <p class="clickableText" @click="modalEditFrequency(backup.id)" > {{backup.frequencyMessage}} </p>
        </div>
         <div class="col-1">
           <p class="tabText" > {{backup.lastBackup}} </p>
        </div>
        <div class="col-1">
          <div class="buttons">
            <b-button
              variant="outline-primary"
              class="botton"
              v-on:click="modalLogs(backup.id)"
              >Details</b-button
            >
            <!-- <b-icon
              class="bicon"
              variant="danger"
              icon="trash-fill"
              aria-hidden="true"
              @click="modalDeleteBackup(backup.id)"
            >
            </b-icon> -->
          </div>
        </div>
         
      </div>

      <b-modal size="xl" id="logs" hide-footer>
        <template #modal-title>
          <span class="modalTitle">Backup Details</span>
        </template>
        <plannerBackupDetails
          @update-backup-details="updateDetails"
          :backupId="backupId"
        />
      </b-modal>
      <b-modal size="xl" title="Connector logs" id="endpoint-logs" hide-footer>
         <template #modal-title>
          <span class="modalTitle"> Connector logs </span>
        </template>
        <endpointLogs :endpointId="endpointId" />
      </b-modal>
      <b-modal size="xl" id="delete-plannerbackup" hide-footer>
        <deletePlannerBackup :backupId="backupId" @delete-plannerbackup="finishBackupDeletion" />
      </b-modal>
      <b-modal size="xl" id="backup-frequency" hide-footer>
          <template #modal-title>
          <span class="modalTitle">Backup Frequency</span>
        </template>
        <plannerBackupFrequency :backupIds="selectedBackups" />
      </b-modal>

      <!-- --------------------------------- Migration Popup ------------------------------ -->

      <b-modal size="xl" id="popup" ref="popup" hide-footer>
        <div>
          <div class="modal-title">Select your destination</div>
          <div class="modal-form">
            <p class="modal-subtitle mt-3">Choose a Target Connector</p>
            <b-form-select
              class="modal-shape col-9"
              v-model="selectedTarget"
              :options="Object.keys(targetsChoices)"
            ></b-form-select>
          </div>

          <div class="modal-button mb-5">
            <b-button
              variant="primary"
              class="modal-botton"
              v-on:click="updateBackups()"
              >Start Backup</b-button
            >
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import sidebar from "../components/sidebar.vue";
import upbar from "../components/upbar.vue";
import api from "../api/index";
import config from "../config/index";
import plannerBackupDetails from "../components/popup/plannerBackupDetails.vue";
import EndpointLogs from "../components/popup/endpointLogs.vue";
import deletePlannerBackup from "../components/popup/deletePlannerBackup.vue";
import plannerBackupFrequency from "../components/popup/plannerBackupFrequency.vue";

export default {
  name: "PlannerBackup",
  components: {
    sidebar,
    upbar,
    plannerBackupDetails,
    EndpointLogs,
    deletePlannerBackup,
    plannerBackupFrequency
  },
  data() {
    return {
      search: "",
      backups: [],
      logged: false,
      selected: [],
      targetEndpoints: [],
      selectAll: false,
      selectedBackups: [],
      targets: [],
      targetsChoices: {},
      needTargetBackups: [],
      selectedTarget: null,
      targetsGroupsMapping: {},
      endpointStates: [],
      test: {},
      backupId: null,
      endpointId: null,
      project: null,
      pullErrorMessage: "",
      showPullErrorAlert: false,
      showErrorAlert: false,
      display_div: true,
      backupErrorMessage: "",
      
    };
  },
  computed: {
    filteredList() {
      return this.filteredListMethod();
    },
    // loadFrequencyMessage(){
    //   console.log('Lets go please' + JSON.stringify(this.backups));
    //   this.backups.forEach(element => {

    //     console.log(element.backupFrequency);
    //     if(element.backupFrequency==='' || element.backupFrequency===null)
    //     {
    //       element.frequencyMessage = '';
    //     }
    //     else if(JSON.parse(element.backupFrequency.Frequency) === 2)
    //     {
    //         element.frequencyMessage = 'Weekly';
    //     }
    //   });
    // },
  },
  watch: {
    selected: function (backupIds) {
      this.needTargetBackups = [];
      this.selectedBackups = [];
      for (const backupId of backupIds) {
        const identifiedBackup = this.backups.find((x) => x.id === backupId);
        if (!identifiedBackup.targetEndpointId) {
          this.needTargetBackups.push(identifiedBackup.id);
        }
        this.selectedBackups.push(identifiedBackup.id);
      }
    },
    selectAll: function (val) {
      this.toggleAll(val);
    },
  },
  created() {
    //this.getProjects();
  },
  mounted() {
    this.project = this.$store.getters.getProject;
    if (!this.project) {
      this.project = this.$route.params.id;
    }
    this.loadBackups();
    this.getTargets(this.$route.params.id);
    this.loadEndpointStatus();
    this.timer = setInterval(() => {
      this.refresh();
    }, 60 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    filteredListMethod() {
      var backupsToReturn =  this.backups.filter((x) => {
        return JSON.stringify(x)
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
      return backupsToReturn;
    },
    setShowErrorAlert(){
        this.showErrorAlert = true;
        setTimeout(() => {
          this.showErrorAlert = false;
        }, 7000);
    },
    getTextAreaValue(id){
      return 'hihi ' + id;
    },
    toggleDiv() {
    this.display_div = !this.display_div;
    },    
    goToConnectorsPage() {
      var projectId = this.$route.params.id;
      this.$router.push("/connectors/" + projectId);
    },
    finishBackupDeletion(backup) {
      const identfiedBackupIndex = this.backups.findIndex((x) => x.id === backup);
      this.backups.splice(identfiedBackupIndex, 1);
    },
    updateDetails(backupDetails) {
      const identifiedBackup = this.backups.find((x) => x.id === backupDetails.id);
      identifiedBackup.targetName = backupDetails.targetName;
    },
    enableOrDisableBackup(backupId, backupState){
      const projectId = this.$route.params.id;
      const identifiedBackup = this.backups.find((x) => x.id === backupId);
        if(identifiedBackup.targetEndpointId==0 || identifiedBackup.targetEndpointId== null)
        {
          //This backup object needs a target connector.
          this.makeToast("Oops, Something went wrong", "danger", "You cannot schedule backup on this object. You need to run a manual backup first.")
          identifiedBackup.isBackupActive = false;
        }
        else 
        {
          api.enableBackup(projectId, backupId, backupState);
          identifiedBackup.frequencyMessage = 'Weekly';
        }
    },
    toggleAll(checked) {
      var itemsWithNoTargetEndpoint = [];
      if(this.search==='')
      {
        //There was NO search query here
          this.selected = checked ? this.backups.map((x) => x.id) : [];
        itemsWithNoTargetEndpoint= this.backups.filter(
            (x) => x.targetEndpointId === null
          );
          this.needTargetBackups = itemsWithNoTargetEndpoint.map((x) => x.id);
      }
      else
      {
        //There was a Search query here
         var searchedBackups = this.filteredListMethod();
         this.selected = checked ? searchedBackups.map((x) => x.id) : [];
          itemsWithNoTargetEndpoint =  searchedBackups.filter(
            (x) => x.targetEndpointId === null
          );
          this.needTargetBackups = itemsWithNoTargetEndpoint.map((x) => x.id);
      }
    },
    startBackup() {
      if (this.needTargetBackups.length > 0) {
        this.$refs["popup"].show();
      } else {
        this.updateBackups();
      }
    },
    stopBackup() {
      const projectId = this.$route.params.id;
        for (var j = 0; j < this.selectedBackups.length; j++) {
          var identifiedBackup = this.backups.find(
            (x) => x.id === this.selectedBackups[j]
          );
            const status = { statusId: 8 };
            api.changePlanBackupStatus(projectId, this.selectedBackups[j], status);
            identifiedBackup.statusId = "Stopping";
        }
        this.selected = [];
    },
    restore() {
      var errorRestore = 0;
      const projectId = this.$route.params.id;
        for (var j = 0; j < this.selectedBackups.length; j++) {
          var identifiedBackup = this.backups.find(
            (x) => x.id === this.selectedBackups[j]
          );
          console.log(identifiedBackup.statusId);
          if(identifiedBackup.lastBackup == null || identifiedBackup.lastBackup == 'Never'){
            errorRestore++;
            this.backupErrorMessage = "Failed to start Restore on " + errorRestore + " items. These boards were never backed up.";
            this.setShowErrorAlert();
          }
          else if(
            identifiedBackup.statusId == "Stopping" || 
            identifiedBackup.statusId == "Backup Scheduled" || 
            identifiedBackup.statusId == "Backup Working" )
          {
            errorRestore++;
            this.backupErrorMessage = "Could not start Restore on " + errorRestore + " items. Invalid State.";
            this.setShowErrorAlert();
          }
          else{
            const status = { statusId: 17 };
            api.changePlanBackupStatus(projectId, this.selectedBackups[j], status);
            identifiedBackup.statusId = "Restore Queued";
          }
        }
        this.selected = [];
    },
    hideModal() {
      this.$refs["popup"].hide();
    },
    modalLogs(backupId) {
      this.backupId = backupId;
      this.$bvModal.show("logs");
    },
    modalEndpointLogs(endpointId) {
      this.endpointId = endpointId;
      this.$bvModal.show("endpoint-logs");
    },
    modalDeleteBackup(backupId) {
      this.backupId = backupId;
      this.$bvModal.show("delete-plannerbackup");
    },
    modalEditFrequency(backupId) {
      this.selectedBackups.push(backupId);
      this.$bvModal.show("backup-frequency");
      
    },
    deleteBackup() {
      const projectId = this.$route.params.id;
      api.deletePlannerBackup(projectId, this.selectedBackups);
      this.selectedBackups.forEach(element => {
        this.finishBackupDeletion(element);
      });
      this.selected = [];
    },
    updateBackups() {
      const projectId = this.$route.params.id;
      var errorCounter = 0;
      //First, assign the target connector to the Backup that are missing a target connector *IF ANY*
      if (this.needTargetBackups.length > 0) {
        //1- Get the target Endpoint Id
        const IdentifiedTargetEndpoint = this.targetEndpoints.find(
          (x) => x.name === this.selectedTarget
        );
        //2- Run the API Assign Target Update
        const dataToPost = {
          targetEndpointId: IdentifiedTargetEndpoint.id,
          objectsIds: this.needTargetBackups,
        };
      
        api.assignTargetEndpointForPlannerBackup(projectId, dataToPost).then(() => {
          //Once the Target connector is assigned, schedule the migration.
          //3- Sschedule the migration
          const status = { statusId: 7 };
          //3-A Check among the selected Backups, which ones are already working or scheduled
          for (var i = 0; i < this.selectedBackups.length; i++) {
            var identifiedBackup = this.backups.find(
              (x) => x.id === this.selectedBackups[i]
            );
            if (
              identifiedBackup.statusId === "Queued" ||
              identifiedBackup.statusId === "Working"
            ) {
              errorCounter++;
              this.backupErrorMessage =
                "Failed to start Backup on " +
                errorCounter +
                " items. Items already scheduled or working.";
             this.setShowErrorAlert();
            } else {
              api.changePlanBackupStatus(projectId, this.selectedBackups[i], status);
              this.loadBackups();
            }
          }
          this.selected = [];
        });
      } else {
        //Selected Backups ALL have a target connector assigned already
        for (var j = 0; j < this.selectedBackups.length; j++) {
          var identifiedBackup = this.backups.find(
            (x) => x.id === this.selectedBackups[j]
          );
          if (
            identifiedBackup.statusId === "Queued" ||
            identifiedBackup.statusId === "Working"
          ) {
            errorCounter++;
            this.backupErrorMessage =
              "Failed to start Backup on " +
              errorCounter +
              " items. Items already scheduled or working.";
            this.setShowErrorAlert();
          } else {
            const status = { statusId: 7 };
            api.changePlanBackupStatus(projectId, this.selectedBackups[j], status);
            identifiedBackup.statusId = "Queued";
          }
        }
        this.selected = [];
      }
      this.$refs["popup"].hide();
    },
    refresh() {
      this.loadBackups();
      this.loadEndpointStatus();
    },
    loadBackups() {
      api.getPlannerBackupOverview(this.$route.params.id).then((response) => {
        this.backups = response.responseData;
             
        for (let item of this.backups) {
          item.statusId = config.BACKUPSTATUS[item.statusId];
          if(item.backupFrequency==='' || item.backupFrequency===null)
          {
            item.frequencyMessage = 'Inactive';
          }
          else if(JSON.parse(item.backupFrequency).Frequency === 1 ){
                item.frequencyMessage = 'Monthly';
          }
          else if(JSON.parse(item.backupFrequency).Frequency === 2 ){
                item.frequencyMessage = 'Weekly';
          }
          else if(JSON.parse(item.backupFrequency).Frequency === 3 ){
                item.frequencyMessage = 'Daily';
          }
          if(item.lastBackup == null){
            item.lastBackup = 'Never';
          }
          else{
            var date = new Date(item.lastBackup)
            item.lastBackup =  date.getDate()+'-'+date.getMonth()+'-'+date.getFullYear();
          }
        }
      });
    },
    loadEndpointStatus() {
      api.getEndpointStatus(this.$route.params.id, 2).then((response) => {
        this.endpointStates = response.responseData;
        for (let item of this.endpointStates) {
          item.statusId = config.BACKUPSTATUS[item.statusId];
        }
      });
    },

    repullConnector(connectorId) {
      this.pullErrorMessage = "";
      const identifiedConnectorState = this.endpointStates.find(
        (x) => x.endpointId === connectorId
      );
      if (
        identifiedConnectorState.statusId === "Queued" ||
        identifiedConnectorState.statusId === "Working"
      ) {
        //Do not pull
        this.showPullErrorAlert = true;
        this.pullErrorMessage =
          "Cannot repull connector. Connector pull is already queued or working";
        setTimeout(() => {
          this.showPullErrorAlert = false;
        }, 2000);
      } else {
        this.pullErrorMessage = "";
        api.repullConnector(this.project, connectorId);
        identifiedConnectorState.statusId = "Queued";
      }
    },
    getTargets(id) {
      api.getEndpoints(id).then((response) => {
        this.targetEndpoints = response.responseData.filter(
          (connector) => connector.isTarget && connector.endpointType == 8 && connector.isForBackup
        );
        this.targetsGroupsMapping = {};
        for (let item of this.targetEndpoints) {
          this.targetsGroupsMapping[item.id] = [];
          this.targetsChoices[item.name] = item.id;
        }
      });
    },
    getTypeLogo(sourceType) {
      const sourceTypes = {
        1: "./trello.png",
        2: "./asana.png",
        3: "./todoist.png",
        8: "./sharepoint.png",
        9: "./wrike",
      };
      var images = require.context("../assets/", false, /\.png$/);
      return images(sourceTypes[sourceType]);
    },
    getLogoConnector(id) {
      const EndpointImages = {
        1: "./trello.png"
      };
      var images = require.context("../assets/", false, /\.png$/);
      return images(EndpointImages[id]);
    },
    makeToast(title, variant, textToShow) {
        this.$bvToast.toast(textToShow, {
          title: title,
          variant: variant,
          toaster: 'b-toaster-top-center',
          solid: false
        })
    },
  },
};
</script>
<style scoped>
.main {
  margin-top: 53px;
  margin-left: 370px;
}
.bar {
  margin-top: 53px;
  margin-right: 40px;
  height: 53px;
  border-radius: 2px;
  background: #f2f6ff;
}
.bar1 {
  margin-right: 40px;
  height: 82px;
  border-radius: 2px;
  border-bottom: 2px solid #dfe0eb;
}
.tabTitle {
  margin-top: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 21px;
  color: #2a4192;
}
.tabText {
  margin-top: 28px;
  font-size: 20px;
  line-height: 21px;
  color: #252733;
}
.clickableText {
  margin-top: 28px;
  font-size: 20px;
  line-height: 21px;
  color: #252733;
  cursor: pointer;
}
.bicon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  vertical-align: -10px;
}
.bicon-trash {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  margin-top: 8px;
  vertical-align: -10px;
}
.bicon-questionmark {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  vertical-align: -10px;
  margin-left: 50px;
  margin-top: 20px;
}
.altosio-icon {
  width: 21px;
  height: 36px;
}
.buttons {
  margin-top: 18px;
}
.botton {
  width: 70px;
  height: 44px;
  margin-right: 25px;
}
.botton-wide {
  width: 110px;
  height: 44px;
  margin-right: 25px;
}
.icon {
  margin-right: 20px;
  margin-top: 20px;
}
.target-icon {
  margin-right: 20px;
  margin-left: 50px;
  margin-top: 20px;
}
.plus {
  float: right;
  margin-right: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  width: 268px;
  height: 50px;
  cursor: pointer;
  background: #2a4192;
  border-radius: 5px;
}

.plus-for-migrate {
  float: right;
  margin-right: 40px;
  font-style: normal;
  line-height: 30px;
  width: 180px;
  height: 50px;
  cursor: pointer;
  border-radius: 5px;
}
.plus-white {
  float: right;
  margin-right: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  width: 268px;
  height: 50px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 5px;
}
.plus-white-trash{
  float: right;
  margin-right: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  width: 30px;
  height: 10px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 5px;
}
.plus-entries {
  float: right;
  margin-right: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2a4192;;
  width: 190 px;
  height: 50px;
  cursor: pointer;
  background: #ffffff;
}
.link {
  color: #2a4192;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
}
.plusIn {
  margin-top: 10px;
  margin-left: 40px;
}
.iconPlus {
  margin-right: 20px;
}
.blue {
  background: #2a4192;
  border-radius: 5px;
}
.title {
  float: left;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.3px;
  margin-top: 48px;
  color: #2a4192;
}
.top {
  margin-top: 60px;
}
.pang {
  position: absolute;
  right: 40px;
}
.pangText {
  position: absolute;
  float: left;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
}
/* ----------------------- */
.group {
  width: 17px;
  height: 17px;
  background-color: gray;
  margin-right: 12px;
  margin-left: 5px;
  margin-top: 28px;
}
.group1 {
  width: 20px;
  height: 20px;
  background-color: gray;
  margin-right: 9px;
  margin-top: 14px;
}
.modal-form {
  margin-left: 28%;
}
.modal-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  text-align: center;

  color: #23438e;
}
.modal-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #23438e;
}
.modal-shape {
  width: 480px;
  height: 56px;
}
.modal-botton {
  margin-top: 30px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.modal-button {
  text-align: center;
}
.modalTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #23438e;
  margin-left: 400px;
  margin-right: auto;
}
.isBackupActiveSwitch {
 margin-top: 24px;
}
</style>
