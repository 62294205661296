<template>
  <div>
    <div class="mb-2 mt-3">
      <div class="all row mt-4">
        <div class="col-6">
          <p class="subtitle">Select a backup frequency</p>
          <div>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio
              class="radioButton"
                v-model="selectedFrequencyOption"
                :aria-describedby="ariaDescribedby"
                value="1"
                >Monthly</b-form-radio
              >
              <b-form-radio
                 class="radioButton"
                v-model="selectedFrequencyOption"
                :aria-describedby="ariaDescribedby"
                value="2"
                >Weekly</b-form-radio
              >
              <b-form-radio
                 class="radioButton"
                v-model="selectedFrequencyOption"
                :aria-describedby="ariaDescribedby"
                value="3"
                >Daily</b-form-radio
              >
            </b-form-group>
          </div>
        </div>
        <div class="col-6">
               <!-- *********** Monthly Details ************** -->
          <p class="subtitle"  v-if="selectedFrequencyOption=='1'"> Day of the Month </p>
            <b-form-select    class="shape col-9" v-if="selectedFrequencyOption=='1'" v-model="selectedDayOfMonth" :options="monthOptionsArray"> </b-form-select>

               <!-- *********** Weekly Details ************** -->
          <p class="subtitle"  v-if="selectedFrequencyOption=='2'"> Day of the Week </p>
            <b-form-select   class="shape col-9" v-if="selectedFrequencyOption=='2'" v-model="selectedDayOfWeek" :options="weekOptionsArray"> </b-form-select>
      
               <!-- *********** Daily Details ************** -->
                  <!-- *********No particular details here ************** -->
              <!-- *********** Hour of the day (Applies to ALL) Details ************** -->
              <p class="subtitle" v-if="selectedFrequencyOption!=''"> Hour of the Day </p>
            <b-form-select   class="shape col-9" v-if="selectedFrequencyOption!=''" v-model="selectedHour" :options="hourOptionsArray"></b-form-select>
        </div>
        <div class="button mb-1">
          <b-button variant="primary" class="editbutton" @click="setFrequency()"
            >Update Frequency</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
export default {
  name: "plannerBackuFrequency",
  data() {
    return {
      frequencyMessage: '',
      selectedFrequencyOption:'',
      selectedHour:'', 
      selectedDayOfWeek: '',
      selectedDayOfMonth: '',
      monthOptionsArray: [],
      weekOptionsArray: [],
      hourOptionsArray: [],
      project: null,
      toggleState: true,
    };
  },
  props: {
    backupIds: [],
  },
  mounted() {
    this.project = this.$store.getters.getProject;
    if (!this.project) {
      this.project = this.$route.params.id;
    }
    api.validateToken().then(
      () => {},
      () => {
        this.$router.push({ path: "/login" });
      }
    );
    this.fillOptionsArrays();
  },
  methods: {
    fillOptionsArrays(){
        for (var i = 1; i <= 30; i++) {
          this.monthOptionsArray.push({value: i, text: i.toString()});
        }

        for (var hr = 0; hr <= 9; hr++) {
          this.hourOptionsArray.push({value: hr, text: '0'+  hr.toString()+ ':00 🧭'});
        }
        for (var hhr = 10; hhr <= 23; hhr++) {
          this.hourOptionsArray.push({value: hhr, text: hhr.toString()+ ':00 🧭'});
        }

        this.weekOptionsArray = [
          {value: 1, text: "🔧 Monday" },
            {value:2, text: "🔧 Tuesday" },
              {value:3, text: "🔧 Wednesday" },
                {value:4, text: "🔧 Thursday" },
                  {value:5, text: "🔧 Friday" },
                    {value:6, text: "🔧 Saturday" },
                      {value:7, text: "🔧 Sunday" }
        ]
    },
     setFrequency() {
      var form  ={
      "plannerIds":  this.backupIds,
      "frequency": {
                      "frequency": parseInt(this.selectedFrequencyOption),
                      "dayOfTheWeek": parseInt(this.selectedDayOfWeek),
                      "dayOfTheMonth": parseInt(this.selectedDayOfMonth),
                      "hourOfTheDay": parseInt(this.selectedHour)
                   }
      }
      api.setBackupFrequency(this.project, form).then(() => {
          this.$bvModal.hide("backup-frequency");
        });
   },
 },
};

</script>

<style scoped>
.all {
  margin-left: 60px;
}
.wrapper {
  height: 400px;
  overflow-y: scroll;
}
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #23438e;
}
.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 1rem;
  color: #23438e;
}
.radioButton {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 1rem;
}
.shape {
  width: 480px;
  height: 56px;
}
.botton {
  width: 150px;
  height: 35px;
  background: #23438e;
  border-radius: 5px;
  margin-left: 60px;
}
.tab {
  background: #23438e;
}
.editbutton {
  margin-top: 30px;
  margin-left: 200px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.button {
  text-align: center;
}
</style>
