<template>
  <div>
    <div class="mb-2 mt-3">
      <b-card no-body>
        <b-tabs pills card>
          <!--  **** Logs ****  -->
          <b-tab title="Logs" active>

          <b-button class="btn bg-transparent btn-outline-light" @click="refreshLogs">
          <b-icon icon="arrow-clockwise" variant="dark" scale="1" title="Refresh logs"></b-icon>
        </b-button>
                <b-table striped hover :items="logs"></b-table>
          </b-tab>
          <b-tab title="Edit">
            <b-card-text>
              <div class="all row mt-4">
                <div class="col-6">
                  <p class="subtitle">Source Name</p>
                  <b-form-input
                    v-model="backupDetail.sourceName"
                    type="text"
                    :disabled="true"
                    class="shape col-9"
                  ></b-form-input>
                  <p class="subtitle mt-3">Source Id</p>
                  <b-form-input
                    v-model="backupDetail.sourceId"
                    type="text"
                    :disabled="true"
                    class="shape col-9"
                  ></b-form-input>
                  <p class="subtitle mt-3">Source Parent</p>
                  <b-form-input
                    v-model="backupDetail.sourceParent"
                    type="text"
                    :disabled="true"
                    class="shape col-9"
                  ></b-form-input>
                </div>
                <div class="col-6">
                  <p class="subtitle">
                    Target Id
                    <b-icon
                      icon="info-circle-fill"
                      scale="1"
                      variant="secondary"
                      title="Id of the target object"
                    ></b-icon>
                  </p>
                  <b-form-input
                    v-model="backupDetail.targetId"
                    type="text"
                    placeholder="Target Id"
                    class="shape col-9"
                  ></b-form-input>
                  <p class="subtitle mt-3">
                    Target Name
                    <b-icon
                      icon="info-circle-fill"
                      scale="1"
                      variant="secondary"
                      title="Name of the target object"
                    ></b-icon>
                  </p>
                  <b-form-input
                    v-model="backupDetail.targetName"
                    type="text"
                    placeholder="Target Email"
                    class="shape col-9"
                  ></b-form-input>
                </div>
                <div class="button mb-1">
                  <b-button 
                    variant="primary"
                    class="editbutton"
                    @click="updateBackup()"
                    >Update Details</b-button
                  >
                </div>
              </div>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import api from "@/api";
export default {
  name: "plannerBackupDetails",
  // components: {
  //   switchToggle,
  // },
  data() {
    return {
      logs: [],
      backupDetail: {},
      backupUpdate: {},
      detailOptions: "logs",
      project: null,
      toggleState: true,
    };
  },
  props: {
    backupId: Number,
  },
  mounted() {
    this.project = this.$store.getters.getProject;
    if (!this.project) {
      this.project = this.$route.params.id;
    }
    api.validateToken().then(
      () => {},
      () => {
        this.$router.push({ path: "/login" });
      }
    );
    this.getBackupLogs(this.project, this.backupId);
    this.getBackup(this.project, this.backupId);
  },
  methods: {
    refreshLogs(){
       this.getBackupLogs(this.project, this.backupId);
    },
    updateBackup() {
      this.backupUpdate = {
        targetId: this.backupDetail.targetName,
        targetName: this.backupDetail.targetId,
      };
      api.updatePlanBackup(this.backupUpdate, this.project, this.backupId).then(() => {
        this.$bvModal.hide("logs");
      });
      this.$emit("update-backup-details", this.backupDetail);
    },
    getBackupLogs(projectId, backupId) {
      api.getPlannerBackupLogs(projectId, backupId).then((response) => {
        this.logs = response.responseData;
        if (this.logs.length > 0) {
          this.logs.forEach((element) => {
            element.timestamp = element.timestamp
              .replace("T", " ")
              .split(".")[0];
          });
        } else {
          const date = new Date();
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();
          const staticLog = {
            timestamp: year + "-" + month + "-" + day,
            message: "No logs to show.",
          };
          this.logs.push(staticLog);
        }
      });
    },
    getBackup(projectId, backupId) {
      api.getPlannerBackup(projectId, backupId).then((response) => {
        this.backupDetail = response.responseData;
      });
    },
    showLogs() {
      this.detailOptions = "logs";
    },
    showEdit() {
      this.detailOptions = "edit";
    },
  },
};
</script>

<style scoped>
.all {
  margin-left: 60px;
}
.wrapper {
  height: 400px;
  overflow-y: scroll;
}
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  text-align: center;

  color: #23438e;
}
.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #23438e;
}
.shape {
  width: 480px;
  height: 56px;
}
.botton {
  width: 150px;
  height: 35px;
  background: #23438e;
  border-radius: 5px;
  margin-left: 60px;
}
.tab {
  background: #23438e;
}
.editbutton {
  margin-top: 30px;
  margin-left: 200px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.button {
  text-align: center;
}
</style>
